'use client';

import { FC, useEffect, useState } from 'react';
import { IconButton } from '@radix-ui/themes';
import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { useTheme } from 'next-themes';

export const ThemeButton: FC = () => {
	const { resolvedTheme, setTheme } = useTheme();

	const [mounted, setMounted] = useState(false);
	useEffect(() => setMounted(true), []);
	if (!mounted) return null;

	const themeIsLight = resolvedTheme === 'light';

	const toggleTheme = () => {
		setTheme(themeIsLight ? 'dark' : 'light');
	};

	const Icon = themeIsLight ? SunIcon : MoonIcon;

	return (
		<IconButton onClick={toggleTheme} variant="ghost" size="3">
			<Icon height="24" width="24" />
		</IconButton>
	);
};
