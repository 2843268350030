'use client';

import {
	Dispatch,
	FC,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { HEADER_LINKS, HEADER_LINK_IS_ACTIVE_FUNCTIONS } from './constants';
import { usePathname } from 'next/navigation';

type ContextType = {
	mobileMenuIsOpen: boolean;
	setMobileMenuIsOpen: Dispatch<SetStateAction<boolean>>;
	toggleMobileMenuIsOpen: () => void;
	activeHref: string | null;
};

const DEFAULT_CONTEXT_VALUE: ContextType = {
	mobileMenuIsOpen: false,
	setMobileMenuIsOpen: () => {},
	toggleMobileMenuIsOpen: () => {},
	activeHref: null,
};

export const HeaderContext = createContext(DEFAULT_CONTEXT_VALUE);

export const HeaderContextProvider: FC<PropsWithChildren> = ({ children }) => {
	// Mobile menu
	const [mobileMenuIsOpen, setMobileMenuIsOpen] =
		useState<ContextType['mobileMenuIsOpen']>(false);

	const toggleMobileMenuIsOpen = useCallback(() => {
		setMobileMenuIsOpen((prevState) => !prevState);
	}, [setMobileMenuIsOpen]);
	//

	// Active link
	const pathname = usePathname();
	const [activeHref, setActiveHref] =
		useState<ContextType['activeHref']>(null);

	const activeLink = useMemo(() => {
		const activeLink = HEADER_LINKS.find((link) => {
			const isActiveFunction =
				HEADER_LINK_IS_ACTIVE_FUNCTIONS[link.isActiveFunctionKey];
			return isActiveFunction(pathname, link.href);
		});
		return activeLink;
	}, [pathname]);

	useEffect(() => {
		setActiveHref(activeLink?.href ?? null);
	}, [activeLink, setActiveHref]);
	//

	return (
		<HeaderContext.Provider
			value={{
				mobileMenuIsOpen,
				setMobileMenuIsOpen,
				toggleMobileMenuIsOpen,
				activeHref,
			}}
		>
			{children}
		</HeaderContext.Provider>
	);
};

export const useHeaderContext = () => {
	const context = useContext(HeaderContext);

	if (!context) {
		throw new Error(
			'useHeaderContext must be used inside the HeaderProvider',
		);
	}

	return context;
};
