'use client';

import { FC } from 'react';
import { HeaderLinkDef } from './types';
import NextLink from 'next/link';
import { Link } from '@radix-ui/themes';
import { useHeaderContext } from './HeaderContextProvider';
import { Flex } from '@radix-ui/themes';

type Props = {
	links: HeaderLinkDef[];
};

export const HeaderLinks: FC<Props> = ({ links }) => {
	const { activeHref } = useHeaderContext();

	return (
		<Flex gap="6">
			{links.map(({ text, href }) => {
				const isActive = activeHref === href;
				const color = isActive ? undefined : 'gray';

				return (
					<Link asChild key={href} color={color}>
						<NextLink href={href}>{text}</NextLink>
					</Link>
				);
			})}
		</Flex>
	);
};
