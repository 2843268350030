'use client';

import { FC } from 'react';
import { useHeaderContext } from './HeaderContextProvider';
import { IconButton, Flex } from '@radix-ui/themes';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';

type Props = {};

export const HeaderMobileMenuButton: FC<Props> = () => {
	const { toggleMobileMenuIsOpen } = useHeaderContext();

	return (
		<Flex display={{ initial: 'inline-flex', md: 'none' }}>
			<IconButton
				variant="ghost"
				size="3"
				onClick={toggleMobileMenuIsOpen}
				color="gray"
			>
				<HamburgerMenuIcon width="24" height="24" />
			</IconButton>
		</Flex>
	);
};
