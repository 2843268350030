'use client';

import { FC, useCallback } from 'react';
import { HeaderLinkDef } from './types';
import { cx } from 'class-variance-authority';
import NextLink from 'next/link';
import { useHeaderContext } from './HeaderContextProvider';
import { Flex, Box } from '@radix-ui/themes';
import { mobileMenuLinkStyle } from './styles';

type Props = {
	links: HeaderLinkDef[];
};

export const MobileMenu: FC<Props> = ({ links }) => {
	const { mobileMenuIsOpen, setMobileMenuIsOpen, activeHref } =
		useHeaderContext();

	const closeMobileMenu = useCallback(() => {
		setMobileMenuIsOpen(false);
	}, [setMobileMenuIsOpen]);

	if (!mobileMenuIsOpen) return null;

	return (
		<Flex
			position="absolute"
			display={{ initial: 'flex', md: 'none' }}
			direction="column"
			p="4"
			pt="2"
			width="100%"
			className={cx(['border-b', 'border-gray-5', 'bg-gray-2'])}
		>
			{links.map(({ href, text }) => {
				const isActive = activeHref === href;

				return (
					<Box
						asChild
						key={href}
						onClick={closeMobileMenu}
						className={mobileMenuLinkStyle({
							active: isActive,
						})}
						p="3"
					>
						<NextLink href={href}>{text}</NextLink>
					</Box>
				);
			})}
		</Flex>
	);
};
