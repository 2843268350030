import {
	GitHubLogoIcon,
	LinkedInLogoIcon,
	InstagramLogoIcon,
} from '@radix-ui/react-icons';
import {
	HeaderLinkDef,
	HeaderLinkIsActiveFunction,
	HeaderLinkIsActiveFunctionKey,
	HeaderSocialLinkDef,
} from './types';

export const HEADER_LINK_IS_ACTIVE_FUNCTIONS: Record<
	HeaderLinkIsActiveFunctionKey,
	HeaderLinkIsActiveFunction
> = {
	equals: (pathname, href) => pathname === href,
	startsWith: (pathname, href) => pathname.startsWith(href),
};

export const HEADER_LINKS: HeaderLinkDef[] = [
	{
		text: 'Home',
		href: '/',
		isActiveFunctionKey: 'equals',
	},
	{
		text: 'Blog',
		href: '/posts',
		isActiveFunctionKey: 'startsWith',
	},
];

export const HEADER_SOCIAL_LINKS: HeaderSocialLinkDef[] = [
	{
		Icon: GitHubLogoIcon,
		href: 'https://github.com/maxsynnott/personal-blog',
	},
	{
		Icon: LinkedInLogoIcon,
		href: 'https://www.linkedin.com/in/maxsynnott',
	},
	{
		Icon: InstagramLogoIcon,
		href: 'https://www.instagram.com/synnott.max',
	},
];
