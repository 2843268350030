import { cva } from 'class-variance-authority';

export const mobileMenuLinkStyle = cva(
	['first:rounded-t-3', 'last:rounded-b-3', 'hover:bg-accent-4'],
	{
		variants: {
			active: {
				true: ['bg-accent-9', 'hover:bg-accent-9', 'text-gray-1'],
			},
		},
	},
);
